<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="'PROVEEDORES'"
      :subtitle="'Formulario para crear y editar proveedores'"
      :hide-buttons="true"
    >
      <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información general</vs-divider>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label for="category" class="w-full select-large">Tipo de seguro</label>
          <v-select
            id="insuranceType"
            name="insuranceType"
            label="name"
            index="id"
            :options="insuranceTypes"
            :placeholder="'Seleccione..'"
            :searchable="true"
            v-model="entryModel.insurance_type"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("insuranceType") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="category" class="w-full select-large">Categoría</label>
          <v-select
            id="category"
            name="category"
            label="name"
            index="id"
            :options="categories"
            :placeholder="'Seleccione..'"
            :searchable="true"
            v-model="entryModel.category"
            v-validate="'required'"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("category") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label for="name" class="w-full select-large">Nombre</label>
          <vs-input
            id="name" name="name" v-model="entryModel.name" v-validate="'required'" class="w-full" autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("name") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="description" class="w-full select-large">Descripción</label>
          <editor
            id="description" name="description" :content.sync="entryModel.description.html" :resourceType="resource"
            v-validate="'required'"
          />
          <span class="text-danger">{{ errors.first("description") }}</span>
          <br/>
        </div>
      </div>

      <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Ubicación</vs-divider>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label for="state" class="w-full select-large">Departamento</label>
          <v-select
            id="state"
            name="state"
            label="name"
            index="id"
            :options="states"
            :placeholder="'Seleccione..'"
            :searchable="true"
            v-model="entryModel.state"
            v-validate="'required'"
            @input="getCities(entryModel.state)"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("state") }}</span>
          <br/>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <label for="city" class="w-full select-large">Ciudad</label>
          <v-select
            id="city"
            name="city"
            label="name"
            index="id"
            :options="cities"
            :placeholder="'Seleccione..'"
            :searchable="true"
            v-model="entryModel.city"
            v-validate="'required'"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("city") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToEntriesList">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateEntry" :disabled="!validateForm">Guardar</vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import Editor from "./../../components/Editor.vue";
  import {Validator} from 'vee-validate';

  let notifier = new Notifier();
  const ENTRY_CLIENT = new EntriesClient("providers");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("providers");
  const STATES_CLIENT = new CoreClient("states");
  const CITIES_CLIENT = new EntriesClient("cities");

  const requiredFields = ["name", "category", "state", "description", "insuranceType"];
  let dict = requiredFields.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {required: "* Este campo es requerido"};
      return previousValue
    },
    {}
  );
  Validator.localize("es", {custom: dict});

  export default {
    data() {
      return {
        resource: "providers",
        categories: [],
        cities: [],
        insuranceTypes: [],
        states: [],
        entryModel: {
          category: null,
          insurance_type: null,
          name: null,
          state: null,
          city: null,
          description: {
            html: null,
          },
        }
      };
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.loadEntryData(this.$route.params.id)
          .then(() => {
            this.$vs.loading.close();
          });
      } else {
        this.$vs.loading();
        this.getInsuranceTypes(false)
          .then(() => {
            this.getCategories();
          })
          .then(() => {
            this.getStates();
          })
          .then(() => {
            this.$vs.loading.close();
          });
      }
    },

    computed: {
      validateForm() {
        return !this.errors.any()
          && requiredFields.reduce((accumulator, currentValue) => {
            return accumulator && this.entryModel[currentValue] !== null;
          })
          && this.entryModel.description.html !== null;
      }
    },

    methods: {
      async getInsuranceTypes(showLoading = true) {
        if (showLoading) {
          this.$vs.loading();
        }

        await ATTRIBUTE_OPTIONS_CLIENT.insuranceTypes({queryStrings: {representation_type: "simple"}})
          .then((response) => {
            this.insuranceTypes = response.data;

            if (showLoading) {
              this.$vs.loading.close();
            }
          })
          .catch(() => {
            if (showLoading) {
              this.$vs.loading.close();
            }

            notifier.notification("error");
          });
      },

      async getCategories(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await ATTRIBUTE_OPTIONS_CLIENT.categories({queryStrings: {representation_type: "simple"}})
          .then(response => {
            this.categories = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getStates(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await STATES_CLIENT.all()
          .then(response => {
            if (showLoading) this.$vs.loading.close();
            this.states = response.data;
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getCities(state, showLoading = true) {
        if (state !== null && state !== undefined && state !== "") {
          if (showLoading) this.$vs.loading();

          await CITIES_CLIENT.all({queryStrings: {state_id: state.id}})
            .then(response => {
              this.cities = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
        } else {
          this.cities = [];
          this.entryModel.city = null;
        }
      },

      async loadEntryData(entryId) {
        await ENTRY_CLIENT.retrieve({pathParameters: {entryId: entryId}})
          .then(response => {
            this.getStates(false)
              .then(() => {
                this.getCities(response.data.state, false)
                  .then(() => {
                    this.getInsuranceTypes()
                      .then(() => {
                        this.getCategories(false)
                          .then(() => {
                            Object.assign(this.entryModel, response.data);
                          });
                      })
                  });

              });
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry() {
        this.cleanModel();

        if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          this.$vs.loading();
          await ENTRY_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.goToEntriesList();
            })
            .catch(error => {
              this.errors = error.response.data.errors;
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });

        } else {
          this.$vs.loading();
          await ENTRY_CLIENT.create({data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.goToEntriesList();
            })
            .catch(error => {
              this.errors = error.response.data.errors;
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })
      },

      goToEntriesList() {
        this.$router.push({name: "providers"});
      }
    },

    components: {
      Editor,
      FormWizard,
      TabContent,
      flatPickr,
      "v-select": vSelect
    }
  };
</script>